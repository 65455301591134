<template>
  <div style="margin: 80px 8px">
    <div class="row -spread">
      <div class="swiper-title">{{ title }}</div>
      <a :href="link.link" v-if="link" class="swiper-link" :class="{'-hidden': link.hidden}" target="_blank">
        {{ link.label }}
        <Icon id="arrow" v-if="link.label"/>
      </a>
      <div v-else-if="cards.length > 4 || $device.mobile" class="swiper-buttons">
        <div class="swiper-button" :class="{'-disabled' : progress === 0}" @click="back()">
          <Icon id="arrow" style="transform: rotate(180deg)"/>
        </div>
        <div class="swiper-button" :class="{'-disabled' : progress === 1}" @click="next()">
          <Icon id="arrow"/>
        </div>
      </div>
    </div>

    <swiper ref="swiperRef" :options="swiperOptions" class="swiper-wrap" @slideChange="change">
      <swiper-slide class="swiper-card" v-for="(card, key) in cards" :key="key" :class="{'-large': card.tags}" >
        <div class="swiper-card__img" :style="{backgroundImage: `url(${card.image})`}"/>
        <div class="swiper-card__title">{{ card.title }}</div>
        <div class="swiper-card__text" v-if="card.geo">
          <Icon id="geo"/>
          {{ card.geo }}
        </div>
        <div v-else-if="card.tags" class="swiper-tags">
          <a v-for="(tag, key) in card.tags" :key="key" class="swiper-tag" :href="`${directoryUrl}?type=${tag.id}`" target="_blank">
            {{tag.name}}
          </a>
        </div>
        <a class="swiper-card__plus" v-if="card.link" :href="card.link" target="_blank">
          <Icon id="plus"/>
        </a>
      </swiper-slide>
      <swiper-slide v-if="!$device.desktop"/>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "SectionSwiper",
  props: {
    title: {type: String},
    cards: {type: Array},
    link: {type: Object}
  },
  data() {
    return {
      progress: 0,
      swiperOptions: {
        slidesPerView: this.$device.tablet ? 4 : 1.5,
        preloadImages: true,
        spaceBetween: 16
      }
    }
  },
  computed: {
    directoryUrl() {
      return this.$i18n.locale === 'fr' ? `${process.env.VUE_APP_VITRINE_URL}/repertoire` : `${process.env.VUE_APP_VITRINE_URL}/en/directory`
    }
  },
  methods: {
    change() {
      this.progress = this.$refs.swiperRef.$swiper.progress
    },
    next() {
      this.$refs.swiperRef.$swiper.slideNext()
    },
    back() {
      this.$refs.swiperRef.$swiper.slidePrev()
    }
  }
}
</script>
