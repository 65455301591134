<template>
  <div class="modal" @click="$router.push({name: 'home'})">
    <div class="modal__content --contact" @click.stop>
      <div class="modal__header" :style="{backgroundImage: `url(${bannerImage})`}">
        <router-link :to="{name: $i18n.locale === 'en' ? 'homeEn' : 'home'}" class="modal__header--close">
          <div>{{ $t('close') }}</div>
          <Icon id="close"/>
        </router-link>
        <div class="row -center -mobile">
          <div class="modal__header--name">
            <Icon id="contact"/>
            <div>{{ labels.banner }}</div>
          </div>
        </div>
      </div>
      <h1 class="modal__title">
        {{ labels.title }}
      </h1>
      <RawHtml :html="labels.description" class="modal__subtitle"></RawHtml>
      <Transition name="fade">
        <form v-if="!sent" action="" class="form" @submit="submit">
          <div class="form__item">
            <label for="name" class="form__label">{{labels.form.name}}</label>
            <input id="name" ref="formInputs.name" type="text" class="form__input" required data-filled="false">
            <div v-if="formErrors.name !== ''" class="form__errors">
              <img :src="errorIcon" alt="" class="form__errors-icon">
              <p>{{formErrors.name}}</p>
            </div>
          </div>
          <div class="form__item">
            <label for="organisation" class="form__label">{{labels.form.organisation}}</label>
            <input id="organisation" ref="formInputs.organisation" type="text" class="form__input" required data-filled="false">
            <div v-if="formErrors.organisation !== ''" class="form__errors">
              <img :src="errorIcon" alt="" class="form__errors-icon">
              <p>{{formErrors.organisation}}</p>
            </div>
          </div>
          <div class="form__item">
            <label for="email" class="form__label">{{labels.form.mail}}</label>
            <input id="email" ref="formInputs.email" type="email" class="form__input" required data-filled="false">
            <div v-if="formErrors.email !== ''" class="form__errors">
              <img :src="errorIcon" alt="" class="form__errors-icon">
              <p>{{formErrors.email}}</p>
            </div>
          </div>
          <div class="form__item">
            <label for="phone" class="form__label">{{labels.form.phone}} <span class="form__optional">({{labels.form.optional}})</span></label>
            <input id="phone" ref="formInputs.phone" type="text" class="form__input">
            <div v-if="formErrors.phone !== ''" class="form__errors">
              <img :src="errorIcon" alt="" class="form__errors-icon">
              <p>{{formErrors.phone}}</p>
            </div>
          </div>
          <div class="form__item --full">
            <label for="message" class="form__label">{{labels.form.message}}</label>
            <textarea id="message" ref="formInputs.message" class="form__textarea" required data-filled="false"/>
            <div v-if="formErrors.message !== ''" class="form__errors">
              <img :src="errorIcon" alt="" class="form__errors-icon">
              <p>{{formErrors.message}}</p>
            </div>
          </div>
          <button type="submit" class="form__button" :class="{'--disabled': !isFormValid}" :disabled="!isFormValid">{{labels.form.send}}</button>
        </form>
      </Transition>
      <Transition name="fade">
        <div v-if="sent" class="form__confirmation">
          <img :src="contactConfirmation" alt="" class="form__confirmation-icon">
          <p>{{labels.form.confirmation}}</p>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import http from '@/extensions/Http';

export default {
  name: 'Contact',
  data() {
    return {
      formErrors: {
        organisation: '',
        email: '',
        message: '',
        name: '',
        phone: ''
      },
      isFormValid: false,
      regExps: {
        organisation: new RegExp(/^[a-zA-ZÀ-ÿ\-' 0-9]{1,50}$/),
        email: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
        message: new RegExp(/^[a-zA-ZÀ-ÿ0-9?$@#()'!,+\-=_:.&€£*%\s]{5,500}$/),
        name: new RegExp(/^[a-zA-ZÀ-ÿ\-' ]{1,50}$/),
        phone: new RegExp(/^[0-9#-]{10,25}$/)
      },
      sent: false
    }
  },
  computed: {
    bannerImage() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/contactBanner.jpg')
    },
    contactConfirmation() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/contactConfirmation.svg')
    },
    errorIcon() {
      // eslint-disable-next-line global-require
      return require('@/assets/icons/errors.svg')
    },
    labels() {
      const isLangFr = this.$i18n.locale === 'fr'
      return {
        banner: isLangFr ? 'Nous contacter' : 'Contact us',
        description: this.$beet.options.contactform.text,
        errors: {
          organisation: {
            empty: isLangFr ? 'Veuillez entrer une entreprise' : 'Please enter a organisation',
            pattern: isLangFr ? 'Veuillez entrer une entreprise valide' : 'Please enter a valid organisation'
          },
          email: {
            empty: isLangFr ? 'Veuillez entrer un courriel' : 'Please enter an email',
            pattern: isLangFr ? 'Veuillez entrer un courriel valide' : 'Please enter a valid email'
          },
          message: {
            empty: isLangFr ? 'Veuillez entrer un message' : 'Please enter a message',
            pattern: isLangFr ? 'Veuillez entrer un message valide' : 'Please enter a valid message'
          },
          name: {
            empty: isLangFr ? 'Veuillez entrer un nom' : 'Please enter a name',
            pattern: isLangFr ? 'Veuillez entrer un nom valide' : 'Please enter a valid name'
          },
          phone: {
            empty: null,
            pattern: isLangFr ? 'Veuillez entrer un numéro de téléphone valide' : 'Please enter a valid phone number'
          }
        },
        form: {
          organisation: isLangFr ? 'Entreprise' : 'Organisation',
          confirmation: isLangFr ? 'Nous avons bien reçu votre message. Merci!' : 'We have received your message. Thanks!',
          mail: isLangFr ? 'Adresse courriel' : 'Mail',
          message: 'Message',
          name: isLangFr ? 'Nom Complet' : 'Full name',
          optional: isLangFr ? 'Facultatif' : 'Optionnal',
          phone: isLangFr ? 'Téléphone' : 'Phone',
          send: isLangFr ? 'Envoyer le message' : 'Send the message'
        },
        title: this.$beet.options.contactform.title
      }
    }
  },
  mounted() {
    Object.values(this.$refs).forEach(input => {
      input.addEventListener('blur', () => {this.validateField(input)})
    })
  },
  methods: {
    send() {
      const data = {
        object: 'Contact Module'
      }
      Object.values(this.$refs).forEach(input => {
        data[input.id] = input.value
      })
      http.post('/contact', data).then(() => {
        this.sent = true
      })
    },
    submit() {
      event.preventDefault()

      this.$recaptcha('contact').then(() => {
        if(this.isFormValid) {
          this.send()
        }
      })
    },
    validateField(input) {
      if(input.value !== '') {
        input.dataset.filled = 'true'
      }

      if(input.value === '' && input.required === true && input.dataset.filled === 'true') {
        this.formErrors[input.id] = this.labels.errors[input.id].empty
        input.dataset.valid = 'false'
      }
      else if(this.regExps[input.id].test(input.value)){
        this.formErrors[input.id] = ''
        if(input.classList.contains('--error')) {
          input.classList.remove('--error')
        }
        input.dataset.valid = 'true'
      }
      else if(input.value !== ''){
        this.formErrors[input.id] = this.labels.errors[input.id].pattern
        input.dataset.valid = 'false'
      }

      if(this.formErrors[input.id] !== '') {
        input.classList.add('--error')
      }
      this.validateForm()
    },
    testPhone() {
      return this.$refs["formInputs.phone"].value === '' ? true : this.regExps.phone.test(this.$refs["formInputs.phone"].value)
    },
    validateForm() {
      this.isFormValid = this.regExps.name.test(this.$refs["formInputs.name"].value)
          && this.regExps.organisation.test(this.$refs["formInputs.organisation"].value)
          && this.regExps.email.test(this.$refs["formInputs.email"].value)
          && this.regExps.message.test(this.$refs["formInputs.message"].value)
          && this.testPhone()
    }
  }
}
</script>
