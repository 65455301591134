<template>
  <div style="margin: 80px 8px">
    <div class="row -spread">
      <div class="swiper-title">{{ title }}</div>
      <a :href="link.link" v-if="link" class="swiper-link" :class="{'-hidden': link.hidden}" target="_blank">
        {{ link.label }}
        <Icon id="arrow"/>
      </a>
      <div v-else class="swiper-buttons">
        <div class="swiper-button" :class="{'-disabled' : progress === 0}" @click="back()">
          <Icon id="arrow" style="transform: rotate(180deg)"/>
        </div>
        <div class="swiper-button" :class="{'-disabled' : progress === 1}" @click="next()">
          <Icon id="arrow"/>
        </div>
      </div>
    </div>

    <swiper ref="swiperRef" :options="swiperOptions" class="swiper-wrap" @slideChange="change">
      <swiper-slide class="swiper-card -sectors" v-for="(card, key) in cards" :key="key" >
        <div class="swiper-card__sector-image" :style="{background: `linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.2)),url(${card.image}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center'}">
          <div class="swiper-card__sector-image--title">{{card.sector}}</div>
          <img :src="card.icon" class="swiper-card__sector-image--icon"/>
        </div>
        <div class="swiper-card__sector-card">
          <div class="swiper-card__title">{{ card.title }}</div>
          <div class="swiper-card__text">
            <Icon id="geo"/>
            {{ card.geo }}
          </div>
          <a class="swiper-card__plus" v-if="card.link" :href="card.link" target="_blank">
            <Icon id="plus"/>
          </a>
        </div>
      </swiper-slide>
      <swiper-slide v-if="!$device.desktop"/>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "SectionSwiperSectors",
  props: {
    title: {type: String},
    cards: {type: Array},
    link: {type: Object}
  },
  data() {
    return {
      progress: 0,
      swiperOptions: {
        slidesPerView: this.$device.tablet ? 4 : 1.5,
        preloadImages: true,
        spaceBetween: 16
      }
    }
  },
  methods: {
    change() {
      this.progress = this.$refs.swiperRef.$swiper.progress
    },
    next() {
      this.$refs.swiperRef.$swiper.slideNext()
    },
    back() {
      this.$refs.swiperRef.$swiper.slidePrev()
    }
  }
}
</script>
