import './styles'
import './startup'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import BeetUI from '@boite-beet/ui-kit'
import BeetAPI from '@boite-beet/api-client'
import { sync } from 'vuex-router-sync'
import { VueReCaptcha } from 'vue-recaptcha-v3';
// import VueAnalytics from 'vue-analytics'

import store from './store'
import router from './router'
import App from './App.vue'
import ScrollUtils from './extensions/ScrollUtils'
import ScrollHub from './extensions/scrollHub'
import { toRem } from './helpers'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(BeetUI)
Vue.use(ScrollUtils, { scrollEventTarget: window })
// Vue.use(VueAnalytics, { id: 'UA-178215821-1', router })

Vue.use(BeetAPI, {
  store,
  apiUrl: process.env.VUE_APP_API_URL,
  mode: 'nova',
  defaultLang: 'fr'
})

Vue.use(ScrollHub, {
  scrollingElement: document.scrollingElement || document.documentElement,
  scrollEventTarget: window,
  reveal: { offset: window.innerHeight * 0.15 }
})

Vue.use(DeviceQueries, {
  phone: `max-width: ${toRem(567)}`,
  tablet: `min-width: ${toRem(568)}`,
  mobile: `max-width: ${toRem(1024)}`,
  laptop: `min-width: ${toRem(1025)}`,
  desktop: `min-width: ${toRem(1360)}`
})

Vue.use(VueAwesomeSwiper)

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY
})

sync(store, router)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
