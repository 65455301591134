<template>
  <div style="margin: 80px 8px">
    <div class="row -spread">
      <div class="swiper-title">{{ title }}</div>
      <div class="swiper-buttons">
        <div class="swiper-button" :class="{'-disabled' : progress === 0}" @click="back()">
          <Icon id="arrow" style="transform: rotate(180deg)"/>
        </div>
        <div class="swiper-button" :class="{'-disabled' : progress === 1}" @click="next()">
          <Icon id="arrow"/>
        </div>
      </div>
    </div>

    <swiper ref="swiperRef" :options="swiperOptions" class="swiper-wrap" @slideChange="change">
      <swiper-slide class="swiper-card -img" v-for="(card, key) in cards" :key="key">
        <div class="swiper-card__img -large" :style="{backgroundImage: `url(${card.image})`}"/>
        <div class="swiper-card__title -img">{{ card.title }}</div>
        <div class="swiper-card__text">
          {{ card.text }}
        </div>
        <a v-if="card.link" class="swiper-card__link" target="_blank" :href="card.link.url">
          {{card.link.label}} <Icon id="arrow"/>
        </a>
      </swiper-slide>
      <swiper-slide v-if="!$device.desktop"/>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "SectionSwiperImage",
  props: {
    title: {type: String},
    cards: {type: Array},
  },
  data() {
    return {
      progress: 0,
      swiperOptions: {
        slidesPerView: this.$device.tablet ? 4 : 1.5,
        preloadImages: true,
        spaceBetween: 16
      }
    }
  },
  methods: {
    change() {
      this.progress = this.$refs.swiperRef.$swiper.progress
    },
    next() {
      this.$refs.swiperRef.$swiper.slideNext()
    },
    back() {
      this.$refs.swiperRef.$swiper.slidePrev()
    }
  }
}
</script>


homePage :  {
  header: {video_url, video_label, title, text}
  card_title: '',
  card_description: '',
  section_title: '',
  section_text: '',
  section_button_label: '',
  section_button_url: ''
}

les cartes pour chaque presentation ont une description ( innovation, recherche, etc.) jme demande si on met cette description la sur l'index
