<template>
  <div class="modal" @click="$router.push({name: 'home'})">
    <div class="modal__content" @click.stop>
      <div class="modal__header" :style="{backgroundImage: `url(${presentation.image})`}">
        <router-link :to="{name: $i18n.locale === 'en' ? 'homeEn' : 'home'}" class="modal__header--close">
          <div>{{ $t('close') }}</div>
          <Icon id="close"/>
        </router-link>

        <div class="row -center -mobile">
          <div class="modal__header--name">
            <Icon :id="presentation.slugs['fr']"/>
            <div>{{ presentation.name }}</div>
          </div>
        </div>
      </div>

      <h1 class="modal__title">
        {{ presentation.title }}
      </h1>

      <h2 class="modal__subtitle">
        {{ presentation.description }}
      </h2>

      <component v-for="(component, key) in content" :key="key"
                 :is="component.name" v-bind="component.props"/>

      <section class="modal__next">
        <template v-if="next === 'vitrine'">
          <a :href="vitrineUrl" target="_blank" class="modal__next--button">
            <Icon id="vitrine" class="modal__next--button-icon"/>
            <div>{{ $t('visit') }}</div>
            <Icon id="arrow" class="modal__next--button-icon-small"/>
          </a>
        </template>
        <template v-else-if="next">
          {{ $t('consultNext') }}
          <router-link :to="{name: 'modal', params: {slug: next.slug}}" class="modal__next--button">
            <Icon :id="next.slugs['fr']" class="modal__next--button-icon"/>
            <div>{{ next.name }}</div>
            <Icon id="arrow" class="modal__next--button-icon-small"/>
          </router-link>
        </template>

      </section>
    </div>
  </div>

</template>

<script>

import SectionImage from "@/components/SectionImage.vue";
import SectionImageTop from "@/components/SectionImageTop.vue";
import SectionVision from "@/components/SectionVision.vue";
import Repeatable from "@/components/Repeatable.vue";
import SectionSwiper from "@/components/SectionSwiper.vue";
import SectionSwiperSectors from "@/components/SectionSwiperSectors.vue";
import SectionSwiperImage from "@/components/SectionSwiperImage.vue";
import SectionImageRight from "@/components/SectionImageRight.vue";

import { getMeta } from '@/helpers/metaHelpers'


export default {
  name: "Modal",
  components: {
    SectionImageRight,
    SectionVision,
    SectionImage,
    SectionImageTop,
    SectionSwiperImage,
    SectionSwiperSectors,
    SectionSwiper,
    Repeatable
  },
  data() {
    return {
      ready: false,
      lastLang: this.$i18n.locale,
      sectionMap: {
        'StatsSection': 'Repeatable',
        'BannerSection': 'SectionImageTop',
        'ImageCardSlider': 'SectionSwiperImage',
        'StudyCardSlider' : 'SectionSwiperImage',
        'CallToActionCard': 'SectionImage',
        'SectorCardSlider': 'SectionSwiperSectors',
        'SectionSwiperSectors': 'SectionSwiper',
        'SelectBusinessSlider': 'SectionSwiper',
        'BusinessFilterSlider': 'SectionSwiper',
        'SplitImageSection': 'SectionImageRight',
        'VisionSection': 'SectionVision'
      }
    }
  },
  metaInfo() {
    if (this.ready) {
      return getMeta({
        title: this.presentation.seoTitle,
        description: this.presentation.seoDescription,
        image: this.presentation.seoImage
      })
    }
  },
  computed: {
    vitrineUrl() {
      return process.env.VUE_APP_VITRINE_URL
    },
    presentation() {
      return Object.values(this.$store.state.beet.data.presentations).find(({slugs}) => Object.values(slugs).includes(this.$route.params.slug))
    },
    next() {
      return this.presentation ? this.$store.state.beet.data.presentations[this.presentation.id + 1] || 'vitrine' : 'vitrine'
    },
    content() {
      const sections = []
      if(this.presentation && this.presentation.content) {
        this.presentation.content.forEach(data => {
          const section = {}
          section.name = this.sectionMap[data.name]
          if(this.sectionMap[data.name] === 'Repeatable') {
            section.props = this.getRepeatableProps(data.attributes.stats)
          }
          else if (this.sectionMap[data.name] === 'SectionImage') {
            section.props = this.getSectionImageProps(data.attributes)
          }
          else if (this.sectionMap[data.name] === 'SectionImageTop') {
            section.props = this.getSectionImageTopProps(data.attributes)
          }
          else if (data.name === 'ImageCardSlider') {
            section.props = this.getSectionImageSliderProps(data.attributes)
          }
          else if (data.name === 'BusinessFilterSlider') {
            section.props = this.getBusinessFilterSliderProps(data.attributes)
          }
          else if (data.name === 'SelectBusinessSlider') {
            section.props = this.getBusinessSelectSliderProps(data.attributes)
          }
          else if (data.name === 'SectorCardSlider') {
            section.props = this.getSectorsSliderProps(data.attributes)
          }
          else if (data.name === 'SplitImageSection') {
            section.props = this.getImageRightProps(data.attributes)
          }
          else if (data.name === 'VisionSection') {
            section.props = { title: data.attributes.title, label: data.attributes.label }
          }
          else if (data.name === 'StudyCardSlider') {
            section.props = this.getStudySliderProps(data.attributes)
          }
          sections.push(section)
        })
      }
      return sections
    }
  },
  watch: {
    '$route.params.slug'() {
      this.fetchData()
    },
    '$i18n.locale'() {
      this.fetchData()
    }
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.ready = false
      const id = this.presentation.id
      if(this.presentation.content && this.lastLang === this.$i18n.locale) {
        this.ready = true
        return
      }
      this.$store.dispatch('fetchSingle', ['presentations', id, {url: `/presentations/${id}?lang=${this.$i18n.locale}`}]).then(() => {
        if(this.presentation.slugs[this.$i18n.locale] !== this.$route.params.slug) {
          this.$router.push({name: this.$route.name, params: {slug: this.presentation.slugs[this.$i18n.locale]}})
          this.ready = true
        } else {
          this.ready = true
        }
      })
    },
    getStudySliderProps(attributes) {
      const cards = []

      attributes.studies.forEach(data => {
        const id = data.attributes.studyId
        const study = this.$beet.studies[id]
        const sector = this.$beet.sectors[study.sectors[0]] || {}
        const directory = this.$i18n.locale === 'fr' ? 'etudes-de-cas' : 'en/studies'
        cards.push({
          title: study.title,
          text: sector.name,
          image: data.attributes.cardImage || '/img/placeholder_h.png',
          link: {
            label: this.$t('consultStudy'),
            url: `${process.env.VUE_APP_VITRINE_URL}/${directory}/${study.slugs[this.$i18n.locale]}`
          }
        })
      })

      return {title: attributes.title, cards}
    },
    getImageRightProps(attributes) {
      return {
        title: attributes.title,
        text: attributes.content,
        image: attributes.splitImage || '/img/placeholder.png',
        imageTop: attributes.titleImage
      }
    },
    getSectionImageSliderProps(attributes) {
      const cards = []
      attributes.cards.forEach(card => {
        cards.push({
          image: card.attributes.cardImage || '/img/placeholder_h.png',
          title: card.attributes.title,
          text: card.attributes.subtitle
        })
      })
      return {title: attributes.title, cards}
    },
    getSectorsSliderProps(attributes) {
      const cards = []
      attributes.sectors.forEach(entry => {
        const businessId = Number(entry.attributes.business)
        const sectorId = Number(entry.attributes.sector)
        const business = this.$store.state.beet.data.businesses[businessId]
        const link = `${this.getRepertoireEntrepriseBaseLink()}/${business.slugs[this.$i18n.locale]}`
        const sector = this.$store.state.beet.data.sectors[sectorId]
        const obj = {
          image: sector.presentationImage || '/img/placeholder_h.png',
          sector: sector.name,
          icon: sector.presentationIcon ||'/img/icon_placeholder_white.png',
          title: business.name,
          geo: this.$store.state.beet.data.regions[business.region].name,
          link
        }
        cards.push(obj)
      })
      return {
        title: attributes.title,
        cards, link: {label: this.$t('discoverSectors'), link: `${process.env.VUE_APP_VITRINE_URL}/${this.$i18n.locale === 'fr' ? 'repertoire' : 'en/directory'}`}}
    },
    getBusinessSelectSliderProps(attributes) {
      const cards = []
      const businesses = []
      attributes.businesses.forEach(id => {
        businesses.push(this.$store.state.beet.data.businesses[id])
      })
      businesses.forEach(business => {
        const link = `${this.getRepertoireEntrepriseBaseLink()}/${business.slugs[this.$i18n.locale]}`
        const card = {
          image: business.logo,
          title: business.name,
          geo: this.$store.state.beet.data.regions[business.region].name,
          link
        }
        cards.push(card)
      })
      const link = attributes.discoverText ? {label: attributes.discoverText, link: attributes.discoverLink} : null
      return {title: attributes.title, cards, link}
    },
    getBusinessFilterSliderProps(attributes) {
      const cards = []
      if(attributes.filter === 'startups') {
        const businesses = Object.values(this.$store.state.beet.data.businesses).filter(({isStartup, categories, logo }) => (isStartup && categories.length && logo)).sort(() => Math.random() - 0.5).splice(0,10)
        businesses.forEach(business => {
          const tags = []
          business.categories.forEach(id => {
            tags.push(this.$store.state.beet.data.categories[id])
          })
          const link = `${this.getRepertoireEntrepriseBaseLink()}/${business.slugs[this.$i18n.locale]}`
          const card = {
            image: business.logo,
            title: business.name,
            tags: tags.slice(0, 3),
            link
          }
          cards.push(card)
        })
      }
      const link = attributes.discoverText ? {label: attributes.discoverText, link: attributes.discoverLink} : null

      return {title: attributes.title, cards, link}
    },
    getRepeatableProps(stats) {
      const items = []
      stats.forEach(({attributes}) => {
        items.push({
          title: attributes.number,
          subtitle: attributes.title,
          img: attributes.icon,
          text: attributes.description
        })
      })
      return { items }
    },
    getSectionImageTopProps(data) {
      return {
        image: data.bannerImage || '/img/placeholder_h.png',
        title: data.title,
        text: data.content
      }
    },
    getSectionImageProps(data) {
      const cta = []
      data.links.forEach(i => {
        cta.push({
          link:  i.attributes.link,
          label: i.attributes.label
        })
      })
      return {
        image: data.ctaBackground || '/img/placeholder_h.png',
        title: data.title,
        subtitle: data.subtitle,
        cta
      }
    },
    getRepertoireEntrepriseBaseLink() {
      const baseLink = this.$i18n.locale === 'fr' ?
          `${process.env.VUE_APP_VITRINE_URL}/repertoire-entreprises` :
          `${process.env.VUE_APP_VITRINE_URL}/en/businesses-directory`
      return baseLink

    }
  }
}
</script>
