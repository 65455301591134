export default {
  close: 'Close',
  home: 'Home',
  explore: 'Explore',
  visit: 'Visit the Vitrine',
  consultNext: 'Consult the next section',
  navTitle: `Quebec: <br/> a world leader in AI`,
  navLabel: 'Discover what makes us unique',
  discoverSectors: 'Discover all sectors',
  consultStudy: 'Consult Study',
  rights: '2022 All rights reserved - Forum IA Québec',
  imagesRights: 'Credit goes to Québec International for the pictures of Québec city used on this website.'
}
