<template>
  <section class="section-image"
           :style="{background: `linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.2)),url(${image}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center'}">
    <div class="section-image__title">{{title}}</div>
    <div v-if="subtitle" class="section-image__subtitle">{{subtitle}}</div>
    <div class="section-image__ctas">
      <a :href="cta.link" target="_blank" v-for="(cta, key) in ctas" :key="key" class="section-image__cta">
        {{cta.label}}
      </a>
    </div>
  </section>
</template>

<script>
export default {
name: "SectionImage",
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
    },
    cta: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    ctas() {
      if(Array.isArray(this.cta)) return this.cta
      else return [this.cta]
     }
  }
}
</script>
