export default {
  close: 'Fermer',
  home: 'Accueuil',
  explore: 'Explorer',
  visit: 'Visiter la Vitrine',
  consultNext: 'Consulter la prochaine facette',
  navLabel: 'Découvrez ce qui nous rend uniques',
  navTitle: `Le Québec, <br/> un leader mondial en IA`,
  discoverSectors: 'Découvrir tous les secteurs',
  consultStudy: 'Consulter l\'étude de cas',
  rights: '2022 Tous droits réservés - Forum IA Québec',
  imagesRights: 'Crédit à Québec International pour les photos de la ville de Québec utilisées sur ce site web.'
}
