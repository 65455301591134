import Vue from 'vue'
import VueRouter from 'vue-router'

import {Contact, Home, NotFound, Modal, Video} from '@/views'

Vue.use(VueRouter)

const routes = [
    {
        path: '/en/',
        name: 'homeEn',
        component: Home,
        children: [
            {
                path: 'contact',
                name: 'contactEn',
                component: Contact
            },
            {
                path: ':slug',
                name: 'modalEn',
                component: Modal
            },
            {
                path: 'video',
                name: 'videoEn',
                component: Video
            }
        ]

    },
    {
        path: '/',
        name: 'home',
        component: Home,
        children: [
            {
                path: 'contact',
                name: 'contact',
                component: Contact
            },
            {
                path: '/:slug',
                name: 'modal',
                component: Modal
            },
            {
                path: 'video',
                name: 'video',
                component: Video
            },
        ]

    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '*',
        name: 'catch-all',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: '-active',
    routes
})

export default router
