<template>
 <section class="section-vision">
   <div class="section-vision__button">
     <Icon id="vision" class="section-vision__button-icon"/>
     {{ label }}
   </div>
   <h2 class="section-vision__title">{{ title }}</h2>
 </section>
</template>

<script>
export default {
name: "SectionVision",
  props: {
    label: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>
