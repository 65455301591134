export const getMeta = seo => {
  return {
    title: seo.title,
    meta: [
      { name: 'description', content: seo.description || '' },
      { name: 'twitter:card', content: 'summary' },
      { property: 'og:type', content: seo.type || 'website' },
      { property: 'og:title', content: seo.title || '' },
      { property: 'og:description', content: seo.description || '' },
      { property: 'og:image', content: seo.image || '' }
    ]
  }
}
