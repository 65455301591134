<template>
  <div class="modal -video" @click.stop="$router.go(-1)">
    <div class="modal__content -video">
      <iframe
          :src="`${video.url}${video.id}${getQueryString(video.options)}`"
          frameborder="0"
          style="position: absolute; top:0; left:0; width: 100%; height: 100%; border-radius: 8px"
          allowfullscreen
          allow="autoplay"
      />
      <router-link :to="{name: 'home'}" class="modal__header--close">
        <div>Fermer</div>
        <Icon id="arrow"/>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      video: {
        url: 'https://www.youtube.com/embed/',
        id: this.$route.query.id,
        options: { controls: 0, autoplay: 1}
      }
    }
  },
  methods: {
    getQueryString(options) {
      return !options
          ? ''
          : `?${Object.entries(options)
              .map(keyValue => keyValue.join('='))
              .join('&')}`
    }
  }
}
</script>
