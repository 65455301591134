<script src="../../carnaval-vue-2022/src/main.js"></script>
<template>
  <main id="app" class="layout" v-if="ready">
    <Transition @after-leave="setScrollPosition" @enter="updateScrollPosition">
      <RouterView class="page layout__view"/>
    </Transition>
    <NavBar v-if="ready"/>
  </main>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
const toDict = arr => arr.reduce((map, obj) => (map[obj.id] = obj, map), {})

export default {
  name: 'App',
  components: {NavBar},
  metaInfo: {
    title: 'Vitrine IA',
  },
  data() {
    return {
      scrollPosition: 0,
      ready: false
    }
  },
  watch: {
    $route({name, hash}, from) {
      (this.$route.name === 'contact' || this.$route.name === 'contactEn') ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
      if (name !== from.name || !hash) return
      const delay = this.$device.tablet ? 0 : 150
      return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), delay)
    },
    '$i18n.locale'(to) {
      if(this.$route.name === 'home' && to === 'en') this.$router.replace({name: 'homeEn'})
      if(this.$route.name === 'homeEn' && to === 'fr') this.$router.replace({name: 'home'})
      if(this.$route.name === 'modal' && to === 'en') this.$router.replace({name: 'modalEn'})
      if(this.$route.name === 'modalEn' && to === 'fr') this.$router.replace({name: 'modal'})
      if(this.$route.name === 'contact' && to === 'en') this.$router.replace({name: 'contactEn'})
      if(this.$route.name === 'contactEn' && to === 'fr') this.$router.replace({name: 'contact'})
      this.fetchData()
    }
  },
  beforeMount() {
    if(this.$route.name === 'homeEn' || this.$route.name === 'modalEn') {
      this.$i18n.locale = 'en'
    }
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.ready = false
      this.$beet.fetchCoreData(this.$i18n.locale).then(() => {
        const parser = (data) => toDict(data)
        this.$store.dispatch('fetchPage', ['presentation', this.$i18n.locale])
        this.$store.dispatch('fetchData', ['presentations', {url: `/presentations?lang=${this.$i18n.locale}`}, parser]).then(() => {
          this.ready = true
        })
      })
    },
    updateScrollPosition() {
      this.$nextTick(() => {
        const {hash} = this.$route
        if (hash) return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), 150)
        this.$scroll.to(this.scrollPosition)
      })
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
      this.$scroll.to(0)
    }
  },
  mounted() {
    if(this.$route.name === 'contact' || this.$route.name === 'contactEn') {
      document.body.classList.add('--lock')
    }
  }
}
</script>
