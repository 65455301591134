<template>
  <section class="section-image-top">
    <div :style="{backgroundImage: `url(${image})`}" class="section-image-top__image"/>
    <div class="section-image-top__title">{{title}}</div>
    <p class="section-image-top__text">{{text}}</p>
  </section>
</template>

<script>
export default {
name: "SectionImageTop",
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    }
}
}
</script>
