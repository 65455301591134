<template>
  <section class="row -wrap -center repeatables" >
    <div v-for="(item, key) in items" :key="key" class="repeatable">
      <div class="repeatable__title">{{item.title}}</div>

      <div class="repeatable__bubble">
        <img :src="item.img" class="repeatable__img"/>
        <div>{{item.subtitle}}</div>
      </div>

      <p class="repeatable__text">{{item.text}}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Repeatable",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
