<template>
  <nav class="navbar" :class="{'-full' : menu}">
    <div class="navbar-items" v-if="$device.laptop">
      <router-link :to="videoUrl" class="navbar-item -full">
        <Icon id="playcircle"/>
        <div v-html="$t('navTitle')"></div>
        <span class="navbar-item__label">{{ $t('navLabel') }}</span>
      </router-link>

      <div class="navbar-items__wrap">
        <router-link :to="i.route" class="navbar-item" v-for="(i, key) in items" :key="key">
          <div class="navbar-item__icon">
            <img :src="
              i.route.params ?
              i.route.params.slug === $route.params.slug ? `/img/icons/${i.icon}_c.svg` : `/img/icons/${i.icon}.svg` :
              `/img/icons/${i.icon}.svg`
            "/>
          </div>

          <div>{{ i.label }}</div>

          <div class="navbar-item__button">
            <Icon :id="i.route.params ? i.route.params.slug === $route.params.slug ? 'eye': 'arrow' : 'arrow'"/>
          </div>
        </router-link>

        <a :href="linkToVitrine" target="_blank" class="navbar-item -blue">
          <div class="navbar-item__icon">
          </div>

          <div> {{ $t('visit') }}</div>

          <div class="navbar-item__button">
            <Icon id="arrow"/>
          </div>
        </a>
      </div>

      <div class="navbar__lang">
        <router-link :to="{name: contactButton.route}" class="navbar__lang-contact">
          <Icon id="contact"/>
          {{ contactButton.label }}
        </router-link>
        <span @click="switchLang">{{ otherLang }}</span>
      </div>
    </div>
    <div v-else class="navbar-mobile">
      <router-link :to="{name: 'home'}">
        <img :src="`/img/VitrineIACI-logo-${$i18n.locale}.svg`" class="navbar-mobile__logo"/>
      </router-link>
      <div class="row -mobile">
        <a class="navbar-mobile__vitrine" :href="linkToVitrine" target="_blank">
          <Icon id="vitrine"/>
        </a>
        <button class="navbar-mobile__menu" @click="menu = !menu">
          <transition name="fade-fast" mode="out-in">
            <Icon v-if="menu" id="close" key="close"/>
            <Icon v-else id="bars" key="open"/>
          </transition>
        </button>
      </div>
    </div>
    <div v-if="menu" style="flex: 1;">
      <div class="navbar-items">
        <router-link :to="videoUrl" class="navbar-item -full">
          <Icon id="playcircle"/>
          <div v-html="$t('navTitle')"></div>
          <span class="navbar-item__label">{{ $t('navLabel') }}</span>
        </router-link>

        <div class="navbar-items__wrap">
          <router-link :to="i.route" class="navbar-item" v-for="(i, key) in items" :key="key">
            <div class="navbar-item__icon">
              <img :src="i.route.name === $route.name ? `/img/icons/${i.icon}_c.svg` : `/img/icons/${i.icon}.svg`"/>
            </div>

            <div>{{ i.label }}</div>

            <div class="navbar-item__button">
              <Icon :id="i.route.name === $route.name ? 'eye': 'arrow'"/>
            </div>
          </router-link>

          <a :href="linkToVitrine" target="_blank" class="navbar-item -blue">
            <div class="navbar-item__icon">
            </div>

            <div>{{ $t('visit') }}</div>

            <div class="navbar-item__button">
              <Icon id="arrow"/>
            </div>
          </a>
        </div>

        <div class="navbar__lang">
          <router-link :to="{name: contactButton.route}" class="navbar__lang-contact">
            <Icon id="contact"/>
            {{ contactButton.label }}
          </router-link>
          <span @click="switchLang">{{ otherLang }}</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      menu: false
    }
  },
  computed: {
    contactButton() {
      const isLangFr = this.$i18n.locale === 'fr'
      return {
        label: isLangFr ? 'Nous contacter' : 'Contact us',
        route: isLangFr ? 'contact' : 'contactEn'
      }
    },
    linkToVitrine() {
      return this.$i18n.locale === 'fr' ? process.env.VUE_APP_VITRINE_URL : `${process.env.VUE_APP_VITRINE_URL}/en`
    },
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'English' : 'Français'
    },
    items() {
      const name = this.$i18n.locale === 'en' ? 'modalEn' : 'modal'
      const links = [
        {icon: 'entreprise', label: 'Entreprise', route: {name, params: {slug: 'entreprise'}}},
        {icon: 'recherche', label: 'Recherche', route: {name, params: {slug: 'recherche'}}},
        {icon: 'talent', label: 'Talent', route: {name, params: {slug: 'talent'}}},
        {icon: 'adoption', label: 'Adoption', route: {name, params: {slug: 'adoption'}}},
        {icon: 'leadership', label: 'Leadership', route: {name, params: {slug: 'leadership'}}},
        {icon: 'ethique', label: 'Éthique', route: {name, params: {slug: 'ethique'}}},
        {icon: 'impact', label: 'Impact', route: {name, params: {slug: 'impact'}}},
      ]

      if (this.$store.state.beet.data.presentations) {
        links.forEach((link, key) => {
          const presentation = this.$store.state.beet.data.presentations[key + 1]
          link.label = presentation.name
          link.route.params.slug = presentation.slugs[this.$i18n.locale]
          link.slugs = presentation.slugs
        })
      }

      return links
    },
    videoUrl() {
      if (this.$beet.pages && this.$beet.pages.presentation) {
        const index = this.$beet.pages.presentation.header.videoUrl.indexOf('v=')
        const id = this.$beet.pages.presentation.header.videoUrl.substr(index + 2)
        return {name: 'video', query: {id}}
      }
      return ''
    }
  },
  watch: {
    'menu'() {
      if (this.menu) {
        if (!document.body.classList.contains('--lock'))
          document.body.classList.add('--lock')
      } else if (this.$route.name !== 'contact' && this.$route.name !== 'contactEn' && document.body.classList.contains('--lock')) {
        document.body.classList.remove('--lock')
      }
    },
    '$route.path'() {
      this.menu = false
    }
  },
  methods: {
    switchLang() {
      this.$i18n.locale === 'fr' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'fr'
    }
  }
}
</script>
