<template>
  <div class="home" v-if="$page">
    <div class="header" v-if="$page.header">
      <img :src="`/img/VitrineIACI-logo-${$i18n.locale}.svg`" class="header__logo" width="220" height="auto" alt="Vitrine Ia Quebec"
           v-scroll:reveal="{delay: 50}"/>
      <div class="header__content">
        <div>
          <h1 class="header__title" v-scroll:reveal="{delay: 150}">
            {{ $page.header.title }}
          </h1>

          <h2 class="header__subtitle" v-scroll:reveal="{delay: 250}">
            {{ $page.header.subtitle }}
          </h2>
        </div>

        <div class="header__discover" v-scroll:reveal="{delay: 300}">
          <div> {{ $page.header.videoLabel }}</div>
          <router-link :to="{name:'video', query: {id: videoId}}" class="header__discover--play" aria-label="play">
            <Icon id="play"/>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row -wrap home__content">
      <div class="row__item -span-8" v-scroll:reveal>
        <div class="card-blue">
          <div class="card-blue__title"> {{ $page.cards.title }}</div>
          <div class="card-blue__text"> {{ $page.cards.subtitle }}
          </div>
        </div>
      </div>

      <div class="row__item -span-4" v-for="(card, key) in cards" :key="card.title"
           v-scroll:reveal="{delay: (key / 2) * 50}">
        <router-link :to="{name: $i18n.locale === 'en' ? 'modalEn' : 'modal', params: {slug : card.slug}}" class="card"
                     :style="{background: `linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.2)),url(${card.image}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center'}">
          <div class="card__icon">
            <Icon :id="card.slugs['fr']"/>
          </div>
          <div class="card__title">{{ card.name }}</div>
          <div class="card__text">{{ card.shortDescription }}</div>
          <div class="card__button"> {{ $t('explore') }}</div>
        </router-link>
      </div>
    </div>

    <section class="home__content">
      <img src="/img/VitrineIA_Symbole.png" alt="vitrineIA" class="home__symbole" v-scroll:reveal/>
      <h3 class="home__title" v-scroll:reveal>{{ $page.vitrine.title }}</h3>
      <p class="home__text" v-scroll:reveal>{{ $page.vitrine.text }}</p>
      <a class="home__button" :href="repertoire" target="_blank" v-scroll:reveal="{delay: 200}">{{ $page.vitrine.buttonLabel }}</a>
    </section>

    <img :src="`/img/VitrineIA_Module_Site${$i18n.locale === 'en' ? 'EN': ''}.png`" style="width: 100%; margin-top: 4rem;" alt="Vitrine IA" v-scroll:reveal/>

    <footer class="footer">
      <div class="footer__section">
        <img :src="`/img/VitrineIA_Descripteur_${$i18n.locale}.png`" class="footer__section-image" alt="Propulsée par Forum IA Québec">
        <RawHtml class="footer__richtext" :html="$page.footer.text"></RawHtml>
        <div style="display: flex">
          <a class="footer__icon" :href="socials.facebook" target="_blank" aria-label="facebook">
            <Icon id="facebook"/>
          </a>
          <a class="footer__icon" :href="socials.linkedin" target="_blank" aria-label="linkedin">
            <Icon id="linkedin"/>
          </a>
          <a class="footer__icon" :href="socials.twitter" target="_blank" aria-label="twitter">
            <Icon id="twitter"/>
          </a>
        </div>
      </div>

      <div class="footer__section -large">
        <img src="/img/Quebec.png" class="footer__section-image" alt="Québec">
        <b>{{ $page.footer.partner1.title }}</b>
        <p>{{ $page.footer.partner1.description }}</p>
      </div>
    </footer>

    <div class="footer__banner">
      <div>{{ $t('rights') }}</div>
      <div>{{ $t('imagesRights') }}</div>
    </div>

    <transition name="fade" appear>
      <router-view :key="$route.fullPath"/>
    </transition>
  </div>
</template>

<script>

import {getMeta} from "@/helpers/metaHelpers";

export default {
  name: 'Home',
  metaInfo() {
    if (this.$page) {
      return getMeta(this.$page.seo)
    }
  },
  computed: {
    videoId() {
      if(this.$page) {
        const cutIndex = this.$page.header.videoUrl.indexOf('v=') + 2
        const id = this.$page.header.videoUrl.substr(cutIndex, this.$page.header.videoUrl.length)
        return id
      } return null
    },
    $page() {
      return this.$beet.pages.presentation
    },
    cards() {
      return this.$store.state.beet.data.presentations || []
    },
    socials() {
      return this.$beet.options.socials
    },
    repertoire() {
      return process.env.VUE_APP_VITRINE_URL
    }
  }
}
</script>
