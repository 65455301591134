<template>
  <section class="section-image-right">
    <div class="section-image-right__left">
      <img :src="imageTop" class="section-image-right__left--image"/>
      <div class="section-image-right__title">{{ title }}</div>
      <p>{{ text }}</p>
    </div>

    <div :style="{backgroundImage: `url(${image})`}" class="section-image-right__image">
    </div>
  </section>
</template>

<script>
export default {
name: "SectionImageRight",
  props: {
    image: {
      type: String,
      required: true
    },
    imageTop: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
