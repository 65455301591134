import scroll from 'scroll'

let ScrollUtilsVM = null

const { body } = document
const { requestAnimationFrame } = window
const defaultScrollingElement = document.scrollingElement || document.documentElement

export default {
  get global() {
    return ScrollUtilsVM
  },
  install(Vue, { scrollingElement = defaultScrollingElement, scrollEventTarget }) {
    ScrollUtilsVM = new Vue({
      name: 'ScrollVM',
      data() {
        return {
          el: scrollingElement,
          position: 0,
          diff: 0,
          storedPosition: null,
          mute: true,
          viewportRefNode: null,
          viewportRefNodeOffset: 0
        }
      },
      computed: {
        ref() {
          const { viewportRefNodeOffset: offset } = this
          const isAbove = offset < 0
          const isBelow = offset > window.innerHeight
          return { isAbove, isBelow, isVisible: !!this.viewportRefNode && !isAbove && !isBelow }
        }
      },
      watch: {
        position(to, from) {
          this.diff = to - from
          if (!this.mute) this.triggerScrollEvent()
          if (this.viewportRefNode) this.viewportRefNodeOffset = this.viewportRefNode.getBoundingClientRect().top
        },
        viewportRefNode(to, from) {
          if (to === from) return
          this.viewportRefNodeOffset = !to ? 0 : this.viewportRefNode.getBoundingClientRect().top
        }
      },
      created() {
        const scrollEventElement = scrollEventTarget || this.el
        let isQueued = true

        const updateScrollPosition = () => {
          this.position = this.el.scrollTop
          isQueued = false
        }

        const queueScrollEvent = () => {
          if (isQueued) return
          requestAnimationFrame(updateScrollPosition)
          isQueued = true
        }

        updateScrollPosition()
        scrollEventElement.addEventListener('scroll', queueScrollEvent)
        this.mute = false
      },
      methods: {
        triggerScrollEvent() {
          this.$emit('scroll', this.position)
        },
        prevent() {
          this.storedPosition = this.position
          this.mute = true
          body.style.position = 'fixed'
          body.style.top = `-${this.storedPosition}px`

          return this.restore
        },
        restore() {
          if (!this.mute) return

          const position = this.storedPosition || 0
          this.storedPosition = null
          this.mute = false
          body.style.position = ''
          body.style.top = ''
          this.to(position)
        },
        on(handler, immediate) {
          if (immediate) handler(this.position)
          this.$on('scroll', handler)
          return () => this.$off('scroll', handler)
        },
        off(handler) {
          this.$off('scroll', handler)
        },
        to(targetPosition = 0, offset = 0, duration) {
          const offsetTargetPosition = targetPosition - offset
          if (duration) return scroll.top(this.el, offsetTargetPosition, { duration })
          this.el.scrollTop = offsetTargetPosition
          this.position = offsetTargetPosition
          this.triggerScrollEvent()
        },
        toTop(offset, duration) {
          this.to(0, offset, duration)
        },
        toBottom(offset, duration) {
          const { scrollHeight = 0 } = this.el
          this.to(scrollHeight, offset, duration)
        },
        toTarget(target, offset, duration) {
          const targetElement = typeof target === 'string' ? this.el.querySelector(target) : target
          if (!targetElement) return

          const { scrollTop } = this.el
          const targetTop = targetElement.getBoundingClientRect().top
          this.to(scrollTop + targetTop, offset, duration)
        }
      }
    })

    Vue.component('ScrollViewportRef', {
      name: 'ScrollViewportRef',
      mounted() {
        this.$nextTick(() => {
          ScrollUtilsVM.viewportRefNode = this.$el
        })
      },
      beforeDestroy() {
        ScrollUtilsVM.viewportRefNode = null
      },
      render: h => h('span')
    })

    Vue.prototype.$scroll = ScrollUtilsVM
  }
}
